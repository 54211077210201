/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { VersionLabel } from '../components';
// import versionString from '../../VERSION';

const versionString = '';

const mapStateToProps = state => ({
  versionString: state.application.versionString,
});

const mapDispatchToProps = dispatch => ({});

/**
 * VersionLabelContainer
 */
class VersionLabelContainer extends PureComponent {
  static propTypes = {
    versionString: PropTypes.string,
  }

  static defaultProps = {
    versionString,
  }

  render() {
    return (
      <VersionLabel versionString={this.props.versionString} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionLabelContainer);
