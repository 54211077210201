/* eslint-disable react/destructuring-assignment */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * VersionLabel
 */
export default class VersionLabel extends PureComponent {
  static propTypes = {
    versionString: PropTypes.string,
    style: PropTypes.shape({}),
    textStyle: PropTypes.shape({}),
  }

  static defaultProps = {
    versionString: 'no-version',
    style: {},
    textStyle: {},
  }

  render() {
    const styles = {
      container: {
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        background: '#dedede',
        padding: '0px 8px',
        borderRadius: '5px',
        opacity: '0.55',
        ...this.props.style,
      },
      text: {
        color: '#515151',
        ...this.props.textStyle,
      },
    };

    return (
      <div style={styles.container}>
        <small style={styles.text}>
          {this.props.versionString}
        </small>
      </div>
    );
  }
}
