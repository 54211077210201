
export const APPLICATION_UPDATE_ERROR = 'APPLICATION_UPDATE_ERROR';
export const APPLICATION_UPDATE_LOADING = 'APPLICATION_UPDATE_LOADING';
export const APPLICATION_UPDATE_TITLE = 'APPLICATION_UPDATE_TITLE';

const defaultState = {
  loading: {
    state: false,
    progress: 0,
  },
  error: {
    state: false,
    message: null,
  },
  version: {
    client: '',
    server: '',
  },
  title: 'Anwendung wird geladen',
  language: 'de',
  server: {
    time: null,
  },
  status: {
    location: {},
    connection: {
      online: true,
    },
  },
};

/**
 * Application reducer
 * @param {Object} [state=defaultState] the previous state
 * @param {Object} action the action to apply
 * @return {Object} the new state
 */
export default function (state = defaultState, action) {
  switch (action.type) {
    case APPLICATION_UPDATE_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case APPLICATION_UPDATE_ERROR:
      return {
        ...state,
        error: {
          state: action.state,
          message: action.message,
        },
      };
    case APPLICATION_UPDATE_LOADING:
      return {
        ...state,
        loading: {
          state: action.state,
          progress: action.progress,
        },
      };
    default:
      return state;
  }
}
