/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateTitle } from './actions';

// POC for withTitle

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  updateTitle: title => dispatch(updateTitle(title)),
});

/**
 * withTitle
 */
export default function withTitle(WrappedComponent, title) {
  /**
   * WithTitle
   */
  class WithTitle extends PureComponent {
    static propTypes = {
      updateTitle: PropTypes.func.isRequired,
    }

    componentDidMount() {
      this.props.updateTitle(title);
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(WithTitle);
}
