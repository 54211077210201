
import { 
  APPLICATION_UPDATE_TITLE,
  APPLICATION_UPDATE_ERROR,
  APPLICATION_UPDATE_LOADING,
} from './reducer';

/**
 * updateTitle
 * @param  {string} title title to set
 * @return {Action} action
 */
export function updateTitle(title) {
  return { type: APPLICATION_UPDATE_TITLE, title };
}

/**
 * updateLoading
 * @param {bool} state to trigger error or not
 * @param {string} message the message to set
 * @return {Action} action
 */
export function updateLoading(state, progress) {
  progress = progress || 0; 
  return { type: APPLICATION_UPDATE_LOADING, state, progress };
}

/**
 * throwError
 * @param {bool} state to trigger error or not
 * @param {string} message the message to set
 * @return {Action} action
 */
export function throwError(state, message) {
  return { type: APPLICATION_UPDATE_ERROR, state, message };
}

/**
 * updateError
 * @param {bool} state to trigger error or not
 * @param {string} message the message to set
 * @return {Action} action
 */
export function updateError(state, message) {
  return { type: APPLICATION_UPDATE_ERROR, state, message };
}
