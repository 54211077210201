/* global document */

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'

import persistStore from 'utils/redux-persist-store';
import configureStore from 'store';

import { Application } from 'Application';
// import { UnprotectedRoutes, ProtectedRoutes, Logout } from 'Authentication';
// import { Login, Registration, PasswordForgot, Scopes, Devices, Profile, Setup, Settings, About } from 'viewcontrollers';
import { Landingpage } from 'viewcontrollers';

import * as serviceWorker from './serviceWorker';
import { analytics } from 'services/google'

//@TODO use locale from settings (store)
require('moment/locale/de');

const store = configureStore({});

const history = createHistory()
history.listen((location, action) => {
  analytics.set({ page: location.pathname })
  analytics.pageview(location.pathname)
});

persistStore(store, {
  whitelist: [
  ],
}, () => {
});

ReactDOM.render((
  <Provider store={store}>
    <Router>
      <Switch>
        <Application>
          <Route path="*" component={Landingpage} />
        </Application>
      </Switch>
    </Router>
  </Provider>), document.getElementById('root'));

serviceWorker.unregister();
