/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { VersionLabelContainer } from './containers';

import { Layout } from 'ui/hoc/Layout';

const mapStateToProps = (state, ownProps) => ({
  title: state.application.title,
  currentNavigationPath: ownProps.location.pathname,
});

const mapDispatchToProps = dispatch => ({
});

/**
 * Application
 */
class Application extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    navigationItems: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })).isRequired,
  }

  static defaultProps = {
    title: 'digitty.io - loading...',
    children: null,
    navigationItems: []
  }

  render() {
    window.document.title = this.props.title;
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Application));
