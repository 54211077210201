import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { experimentalAutoRehydrate } from 'utils/redux-persist-store'

import application from 'Application/reducer';
import authentication from 'Authentication/reducer';

/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const rootReducer = experimentalAutoRehydrate(combineReducers({
  application,
  authentication,
}));

/**
 * configureStore returns a new store
 * @param  {Object} initialState the initial state
 * @return {Object}              the store
 */
export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );
}
