export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const DEAUTHENTICATE_USER = 'DEAUTHENTICATE_USER';
export const ERROR = 'ERROR';

const defaultState = {
    accessToken: '',
    authenticated: false,
  };

/**
 * Authentication reducer
 * @param  {Object} [state=defaultState] the previous state
 * @param  {Object} action               the action to apply••••••••
 * @return {Object}                      the new state
 */
export default function (state = defaultState, action) {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        errors: {},
        authenticated: true,
        accessToken: action.token,
        user: action.user,
      };
    case DEAUTHENTICATE_USER:
      return {
        ...state,
        authenticated: false,
        accessToken: '',
        user: {},
      };
    //@FIXME use general implementation of Application package
    case ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...{
            param: action.param,
            message: action.message,
          }
        },
      };
    default:
      return state;
  }
}
