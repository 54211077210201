import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import theme from 'ui/theme'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

const styles = {
    main: {
        paddingLeft: 50,
        boxSizing: 'border-box',
        backgroundColor: 'rgba(148, 148, 148, 0.2)',
        position: 'relative',
        zIndex: 1,
        marginLeft: 56,
        width: '100%',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            minWidth: 264,
            width: '100%',
            maxWidth: 543,
            paddingLeft: 0,
        },
    },
    menuOpen: {
    },
}

/**
 * @param showNavigationMenu
 * @param activeItem
 * @returns {*}
 */
class Layout extends PureComponent {
    state = {
        showNavigationMenu: this.props.showNavigationMenu,
    }

    static defaultProps = {
        showNavigationMenu: false,
        navigationItems: [],
        currentNavigationPath: "/",
    }

    static propTypes = {
        showNavigationMenu: PropTypes.bool,
        currentNavigationPath: PropTypes.string,
        navigationItems: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          icon: PropTypes.node,
        })).isRequired
    }

    menuToggleHandler = () => {
        this.setState(prevState => ({ showNavigationMenu: !prevState.showNavigationMenu }))
    }

    render () {
        const { classes } = this.props

        return (
            <Grid container wrap="nowrap" style={{ minHeight: '100vh' }}>
                <main className={classNames(this.props.classes.main, classes[(this.state.showNavigationMenu) ? 'menuOpen' : null])}>
                    {this.props.children}
                </main>
            </Grid>
        )
    }
}

export default withStyles(styles)(Layout)
