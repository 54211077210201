import React, { PureComponent, Fragment } from 'react'
import theme from 'ui/theme'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

// Material components
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import Logo from 'ui/assets/Logo-Icon.svg'

const styles = {
    root: {
        padding: theme.spacing.unit * 4,
        maxWidth: 420,
        boxSizing: 'border-box',
        width: '100%',
    },
}

/**
 *
 * @param classes
 * @param error
 * @returns {*}
 */
class Landingpage extends PureComponent {
    state = {
    }

    static defaultProps = {
    }

    static propTypes = {
        classes: PropTypes.objectOf(PropTypes.string).isRequired,
    }

    render () {
        return (
            <div style={{height: "90vh"}}>
                <Grid container justify="center" style={{height: "85%"}}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid container justify="center" alignItems="center">
                           <div><img style={{ width: '100%', maxWidth: '300px' }} src={Logo} alt="digitty.io" /></div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Landingpage)
