import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Landingpage from 'ui/components/Landingpage'

/* 
 * @param classes
 * @param error
 * @returns {*}
 */
class LandingpageView extends PureComponent {
    state = {
    }

    static defaultProps = {
    }

    static propTypes = {
        title: PropTypes.string,
    }

    render () {
        return (
            <Landingpage title={this.props.title} />
        )
    }
}

export default LandingpageView;
