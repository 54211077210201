/* create a custom mahnalarm mui theme*/
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1200,
            xl: 1920,
        },
    },
    /* color object*/
    palette: {
        primary: {
            main: 'rgba(45, 114, 127, 0.92)',
            dark: '#2D727F',
            contrastText: 'rgba(250, 250, 250, 1)',
        },
        secondary: {
            main: '#707070',
            dark: 'rgba(60, 60, 60, 0.7)',
            contrastText: 'rgba(148, 148, 148, 0.2)',
        },
        error: {
            main: 'rgba(127, 45, 67, 0.92)',
            dark: 'rgba(127, 45, 67, 1)',
            contrastText: 'rgba(127, 45, 67, 0.7)',
        },
        card: {
            orange: 'rgba(232, 119, 26, 1)',
            red: 'rgba(153, 0, 0, 1)',
            blue: 'rgba(0, 112, 186, 1)',
            darkblue: 'rgba(45, 49, 144, 1)',
            purple: 'rgba(45, 49, 144, 1)',
            green: 'rgba(0, 144, 68, 1)',
        },
        informationIcon: 'rgba(0, 0, 0, 0.7)',
        custom: {
            hoverItem: '#007E8B',
            activeItem: '#004653',
            selectedMenuItem: '#EEEEEE',
            menuHeader: '#939393',
            warning: '#7F2D43',
        },
        svgs: {
            rotatingFanSVG: '#990000',
            dropSVG: '#0070BA',
            leafSVG: '#009044',
            fanSVG: '#E8771A',
        },
    },
    /* typography object*/
    // TODO:: https://material.io/design/typography/the-type-system.html#type-scale
    typography: {
        fontFamily: 'Roboto',
        fontSize: 14,
        headline: {
            fontSize: 20,
            color: '#004653',
        },
        subheading: {
            fontSize: 18,
            lineHeight: '18px',
            color: 'rgba(60, 60, 60, 1)',
        },
        body1: {
            fontSize: 14,
            lineHeight: '14px',
        },
        caption: {
            fontSize: 12,
            color: 'rgba(60, 60, 60, 0.7)',
            marginBottom: 15,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,
            },
            outlined: {
                borderColor: 'rgba(45, 114, 127, 0.92)',
            },
            textPrimary: {
                color: 'rgba(45, 114, 127, 0.92)',
            },
            disabled: {
                borderColor: 'rgba(0, 0, 0, 0.26)',
            },
        },
    },
})

export default theme
