import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTitle } from 'Application';

import LandingpageView from 'ui/views/Landingpage/Landingpage'

const title = 'digitty.io';

const mapStateToProps = state => ({
  title: state.application.title,
});

const mapDispatchToProps = dispatch => ({
});

/**
 * Landingpage
 */
class Landingpage extends PureComponent {
  static propTypes = {
  }

  static defaultProps = {
  }

  render() {
    return (
      <LandingpageView title={title} />
    );
  }
}

export default withTitle(withRouter(connect(mapStateToProps, mapDispatchToProps)(Landingpage)), title);
